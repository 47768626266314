import React from 'react';
import cl from 'classnames';
import { withNamespaces } from 'react-i18next';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import s from './Application.module.scss';
import storeImg from '../../assets/img/apple-store-white.svg';
import Link from '../Link';
import Api from '../../utils/api';
import { removeTags } from '../../utils/strings';
import SiteTitle from '../PageHeaderTitle';

import img from '../../assets/img/applications/apple-i-phone-x-space-grey.png';
import img2 from '../../assets/img/applications/apple-i-phone-x-space-grey@2x.png';
import img3 from '../../assets/img/applications/apple-i-phone-x-space-grey@3x.png';

import card10Img from '../../assets/img/applications/ik-ev-2.svg';
import card1Img from '../../assets/img/applications/nologs.svg';
import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card5Img from '../../assets/img/applications/global.svg';
import card6Img from '../../assets/img/applications/group-10.svg';
import card9Img from '../../assets/img/applications/connect.svg';

export const ApplicationIos = withNamespaces()(({ t, gatsbyUrl, pythonUrl, i18n, brand }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_ios')}
      text={t('page_applications:text_1_ios')}
      list={t('page_applications:list_ios')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_ios')}
      img_title={`${brand.name} ${t('page_applications:image_alt_ios')}`}
      maxHeight='500px'
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_ios'))} />
      <div className={s.buttons}>
        <Link className={cl(s.button, s.button_store, s.button_accent)} to={api.applicationIosAppStoreUrl()}>
          <img
            src={storeImg}
            alt={t('layout_footer:download_app_appstore')}
          />
        </Link>
        <Link className={cl(s.button, s.button_dark)} to={api.applicationIosGuideUrl()}>
          {t('page_applications:setup_guides')}
        </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesIos = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_10_title'), text: t('page_applications:card_10_text'), imgSrc: card10Img },
    { title: t('page_applications:card_1_title'), text: t('page_applications:card_5_text'), imgSrc: card1Img },
    { title: t('page_applications:card_9_title'), text: t('page_applications:card_9_text'), imgSrc: card9Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewIos = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_2')}
    author={t('page_applications:anonymous')}
  />
));
